<template>

<div class="panel has-text-weight-semibold">
    <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
        <div class="columns is-mobile">
            <div class="column is-size-6 has-text-centered">DEPARTMENT MAPPING</div>
        </div>
    </div>    
    <div class="form" v-if="getActiveStore.role != 'STAFF'">
        <form action="" @submit.prevent="handleSubmit">
        <hr/>
        <div class="box">
        <b-table 
            :data='departments'
            :paginated="isPaginated"
            :per-page="perPage"
            paginationPosition="both"
            sortIcon="arrow-up"
            sortIconSize="is-small"
            default-sort=delivery.endDate
            defaultSortDirection='asc'
            >

            <template>
                <b-table-column field="active" label="Active"  v-slot="props">
                    <i class="fas fa-check-circle  has-text-success" v-if="props.row.active"></i>
                    <i class="fas fa-dot-circle  has-text-danger" v-else></i>
                </b-table-column>

                <b-table-column field="name" label="Department Name" searchable sortable sorticon  v-slot="props">
                    {{ props.row.name }}
                </b-table-column>

                <b-table-column field="departmentType" label="Type" sortable sorticon  v-slot="props">
                    {{ props.row.departmentType }}
                </b-table-column>

                <b-table-column field="Sales Summary Mapping" label="Sales Summary Mapping" sortable sorticon  v-slot="props">
                    <select v-model="props.row.storeMapping" class="veeselect">
                        <option class="items" v-for="item in deptMappings" :key="item" :value="item">{{ item }}</option>
                    </select>
                </b-table-column>

                <b-table-column field="percentage" label="Margin%" sortable sorticon  v-slot="props">
                    <!-- <b-field>
                        <b-input custom-class="has-text-right"
                            type="text" step="any"
                            v-model="props.row.percentage" 
                            @focus="$event.target.select()"
                            placeholder="Profit%">
                        </b-input>
                    </b-field>  -->
                    <b-field>
                        <VeeInput
                            type="number"
                            v-model="props.row.percentage"
                            name="percentage"
                            :value="props.row.percentage"
                            :editable="isEditing"
                            postfix="%"
                            />
                    </b-field>
                </b-table-column>

            </template>

            <template slot="top-left">
                <a class="button is-primary" v-on:click="saveChanges">
                    <i class="fas fa-user">&nbsp;&nbsp;Save Changes</i>
                </a>
            </template>

            <template slot="bottom-left">
                <a class="button is-primary" v-on:click="saveChanges">
                    <i class="fas fa-user">&nbsp;&nbsp;Save Changes</i>
                </a>
                &nbsp;
                <b-notification type="is-info is-light" :closable="false">{{ profitCalculationMethodMessage }}</b-notification>
                
            </template>
            <template slot="empty">
                <data-loading 
                    :isLoading="isLoading" 
                    :isFullPage="isFullPage"> 
                </data-loading>
            </template> 
        </b-table>
        </div>
        </form>       
    </div>
</div>
</template>

<script>

import UserStore from "../../../../../store/UserStore"

import DataLoading from '../../../../../components/app/DataLoading'
import VeeInput from '../../../../../components/app/VeeInput'

import axios from 'axios'

export default {

    components: {
        DataLoading,
        VeeInput
    },

    metaInfo: {
        title: 'Update Department Mapping and Profit Margin'
    },

    data() {
        return {
            departments: [],
            orgDept:[],
            isLoading: false,
            isFullPage: true,
            isPaginated: true,
            isEditing: true,
            perPage: 20,
            deptMappings: []

        }
    },

    methods: {

        saveChanges() {

            // this.isLoading = true

            const url = process.env.VUE_APP_API_ROOT_URL + '/departments'

            axios.put(url, 
                this.departments,
            {
                headers: {
                    'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                    'Refresh-Token': UserStore.getters.getRefreshToken
                },
            })
            .then ((response) => {


                this.isLoading = true
                this.departments = []

                var json = response.data.data

                setTimeout( () => {

                    this.$buefy.notification.open({
                            message: "Changes Updated",
                            type: 'is-info',
                            duration: 6000,
                            'has-icon': true,
                            'icon-pack': 'fas',
                            icon: 'exclamation-circle'
                        })

                    this.isLoading = false
                    this.departments = []
                    
                    // this.$router.push({ path: '/'})

                    this.departments = Object.keys(json).map(function (key) {
                            return json[key];
                        })
                        .sort(function (itemA, itemB) {
                            return itemA.name > itemB.name;
                        });

                }, 500)
            })
            .catch( (error) => {
                this.$buefy.notification.open({
                        message: error.response.data,
                        type: 'is-danger',
                        duration: 6000,
                        'has-icon': true,
                        'icon-pack': 'fas',
                        icon: 'exclamation-circle'
                    })

                this.selectForm("Reset Password Failed")             
            })
            this.isLoading = false

        },

        fetchProductDepartments() {
            if (this.getActiveStore) {
                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.getActiveStore.storeId 
                            + '/departments'

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        var json = response.data.data

                        // this.departments = response.data.data

                        // console.log(this.departments)

                        this.departments = Object.keys(json).map(function (key) {
                                return json[key];
                            })
                            .sort(function (itemA, itemB) {
                                return itemA.name > itemB.name;
                            });

                        this.isLoading = false
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                        this.isLoading = false
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    })
            }
            
        },
    },

    computed: {

        getActiveStore() {
            return UserStore.getters.getActiveStore
        },

        profitCalculationMethodMessage() {
            if (this.getSetting("profitCalculationMethod") == "Margin") {
                return "eg:- Cost $100, 20% Margin, Sales Price will be $ $125. Margin is 20% of sales price."
            } else {
                return "eg:- Cost $100, 20% Markup, Sales Price will be $120, Markup is 20% of cost."
            }    
        }

    },
    
    mounted() {
        this.deptMappings = UserStore.getters.getDeptMappings
        this.fetchProductDepartments()
    }

}

</script>

<style>
.veeselect {
  /* appearance: none; */
  background-color: rgb(249, 249, 238);
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  color: black;
  cursor: inherit;
  line-height: inherit;
}

.veeselect .items {
    background-color: rgb(249, 249, 238);;
    color: black;
}
</style>